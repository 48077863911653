import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

// state
import { ArchiveState, getArchivedPatientsAsync, setPageSizeAsync, setPageAsync, setSort } from './archiveSlice'

import Spinner from '../../layouts/Spinner'


// MUI
import { DataGrid, GridToolbar, getGridStringOperators } from '@mui/x-data-grid';
import { Link,Tooltip } from "@mui/material"
import CheckCircleSharpIcon from '@mui/icons-material/CheckCircleSharp';
import HighlightOffSharpIcon from '@mui/icons-material/HighlightOffSharp';
import PersonSharpIcon from '@mui/icons-material/PersonSharp';


const ArchivePatientsDG = props => {
    const dispatch = useDispatch()
    //Destructure of People State elements
    const { patients, pageSize, page, peopleCount, previouslyLoaded,sort  } = useSelector(ArchiveState)
    let filter

    useEffect(() => {
        dispatch(getArchivedPatientsAsync(page,pageSize,filter,sort))
    }, [pageSize,page])



    const setPageSize = (newPageSize) => {
        dispatch(setPageSizeAsync(newPageSize))
    }

    const setPage = (newPage) => {
        dispatch(setPageAsync(newPage))
    }

    const onFilterChange = React.useCallback((filterModel) => {
        filter = filterModel.items
        dispatch(getArchivedPatientsAsync(page,pageSize,filter))
      }, []);

    const handleSortModelChange = React.useCallback((sortModel) => {
        dispatch(setSort(sortModel))
        dispatch(getArchivedPatientsAsync(page,pageSize,filter,sortModel))
      }, []);


    //Defines People Data Grid columns
    const columnsFormat = [
        {field: 'viewDetails', headerName: '', width: 50, sortable: false, align: 'center',filterable: false, sortable: false,
            renderCell: (params) => {
                return <Link href={"/people/"+ params.row.id}><Tooltip title='View Details'><PersonSharpIcon /></Tooltip></Link>
            }},
        {field: 'firstName', headerName: 'First Name', width: 150,
            renderCell: (params) => {
                return params.row.firstName
            }},
        {field: 'lastName', headerName: 'Last Name', width: 150,
            renderCell: (params) => {
                return params.row.lastName
            }},
        {field: 'createdAt', headerName: 'Date Added', width: 150,
            renderCell: (params) => {
                return moment(params.row.createdAt).isValid() ? moment(params.row.createdAt).format('YYYY-MM-DD') : ''
            }},
        { field: 'birthDate', headerName: 'Birth Date', width: 150,
            renderCell: (params) => {
                return params.row.birthDate
            }},
        {field: 'isCwEnrolled', headerName: 'CW Linked?', width: 100, filterable: false,sortable: false,
            renderCell: (params) => {
                return params.row.isCwEnrolled ? <CheckCircleSharpIcon style={{color: 'green'}} />: <HighlightOffSharpIcon style={{color: 'red'}} />
            }},
        {field: 'returnedData', headerName: 'Reciprocal Data', width: 125, filterable: false,sortable: false,
            renderCell: (params) => {
                return params.row.returnedData ? <CheckCircleSharpIcon style={{color: 'green'}} />: <HighlightOffSharpIcon style={{color: 'red'}} />
            }},
        {field: 'address1', headerName: 'Address Line 1', width: 250, filterable: false,sortable: false,
            valueGetter: (params) => {
                let result = [];
                if (params.row.Addresses) {
                    if (params.row.Addresses[0].line1) {
                    result.push(params.row.Addresses[0].line1);
                    }
                } else {
                    result = [""];
                }
                return result.join(", ");
            }},
        {field: 'address2', headerName: 'Address Line 2', width: 200, filterable: false,sortable: false,
            valueGetter: (params) => {
                let result = [];
                if (params.row.Addresses) {
                    if (params.row.Addresses[0].line2) {
                    result.push(params.row.Addresses[0].line2);
                    }
                } else {
                    result = [""];
                }
                return result.join(", ");
            }},
        { field: 'city', headerName: 'City', width: 200, filterable: false,sortable: false,
            valueGetter: (params) => {
                let result = [];
                if (params.row.Addresses) {
                    if (params.row.Addresses[0].city) {
                    result.push(params.row.Addresses[0].city);
                    }
                } else {
                    result = [""];
                }
                return result.join(", ");
            }},
        {field: 'state', headerName: 'State', width: 150, filterable: false,sortable: false,
            valueGetter: (params) => {
                let result = [];
                if (params.row.Addresses) {
                    if (params.row.Addresses[0].state) {
                    result.push(params.row.Addresses[0].state);
                    }
                } else {
                    result = [""];
                }
                return result.join(", ");
            }},
        {field: 'postalCode', headerName: 'Zip Code', width: 150, filterable: false,sortable: false,
            valueGetter: (params) => {
                let result = [];
                if (params.row.Addresses) {
                    if (params.row.Addresses[0].postalCode) {
                    result.push(params.row.Addresses[0].postalCode);
                    }
                } else {
                    result = [""];
                }
                return result.join(", ");
            }},
        { field: 'email', headerName: 'Email', width: 300, filterable: false,sortable: false,
            valueGetter: (params) => {
                let result = [];
                if (params.row.ContactPoints) {
                    params.row.ContactPoints.forEach((point) => {
                    if (point.system === 'email') {
                        result.push(point.value);
                    }
                })
                } else {
                    result = [""];
                }
                return result.join(", "); 
        }},
        { field: 'phone', headerName: 'Phone', width: 150, filterable: false,sortable: false,
            valueGetter: (params) => {
                let result = [];
                if (params.row.ContactPoints) {
                    params.row.ContactPoints.forEach((point) => {
                    if (point.system === 'phone') {
                        result.push(point.value);
                    }
                })
                } else {
                    result = [""];
                }
                return result.join(", "); 
        }}
        
    ]

    const columns = React.useMemo( () =>
          columnsFormat.map((col) => {
            return {
              ...col,
              filterOperators: getGridStringOperators().filter(
                (operator) => operator.value === 'contains' || operator.value === 'equals',
              ),
            };
          }),
        [columnsFormat],
      );

    if(patients.length > 0 || previouslyLoaded) {
        return (<Fragment>
            <div>
                <div style={{ height: 650, width: '100%' }}>
                    <DataGrid
                        components={{
                            Toolbar: GridToolbar
                        }}
                        componentsProps={{
                            toolbar: {
                              csvOptions: { disableToolbarButton: true },
                              printOptions: { disableToolbarButton: true },
                            },
                        }}
                        style={{
                            backgroundColor: 'white'
                        }}
                        rows={patients}
                        rowCount={peopleCount}
                        columns={columns}
                        page={page}
                        onPageChange={(newPage) => setPage(newPage)}
                        pageSize={pageSize}
                        rowsPerPageOptions={[25,50,100]}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        paginationMode="server" 
                        filterMode='server'
                        onFilterModelChange={onFilterChange}
                        sortingMode="server"
                        onSortModelChange={handleSortModelChange}
                        
                    />
                </div>
            </div>
            </Fragment>
        )
        }
    // If state people array is not populated this returns
        else
            return <Spinner />
}


export default ArchivePatientsDG