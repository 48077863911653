import React, { useEffect, Fragment } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux"

// mui
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Link, Typography, Chip
} from "@mui/material";
import { red, grey } from '@mui/material/colors';
import {  Error } from "@mui/icons-material";

const BatchDetailTable = ({details, batch}) => {

    const headers = [
        {
            getValue: function(obj) {
                if (obj.validationErrors) return <Typography style={{color: red[600]}}><Error /></Typography>
                if (!obj.personId) return <Typography style={{color: grey[400]}}><Error /></Typography>
                return ""
            }
        },
        {
            property: 'rowNumber', label: 'Row', getValue: function(obj) {
                return obj[this.property]
            },
        },
        {
            property: 'batchId', label: 'Batch Id', getValue: function(obj) {
                return obj[this.property]
            },
        },
        {
            property: 'firstName', label: 'First Name', getValue: function(obj) {
                return obj[this.property]
            },
        },
        {
            property: 'lastName', label: 'Last Name', getValue: function(obj) {
                return obj[this.property]
            },
        },
        {
            property: 'personId', label: 'Link', getValue: function(obj) {
                if (obj.personId) return <Link href={`/people/${obj.personId}`}>Go to Person</Link>
                return ""
            },
        },
        {
            property: "status", label: "Status", getValue: function(obj) {
                const newOrExisting = { new: { label: "New", color: "success"}, existing: {label: "Existing", color: 'info'} }

                if (!batch.isValid)
                    return obj.validationErrors ? <Chip color='error' label='Invalid Entry'></Chip> : <Chip variant='outlined' label='Not imported'></Chip>

                if (obj.personId) return <Chip variant='outlined' color={newOrExisting[obj.newOrExisting].color} label={newOrExisting[obj.newOrExisting].label}></Chip>

                return <Chip color='error' label='Error'></Chip>
            }
        },
        {
            property: 'errors', label: 'Errors', getValue: function(obj) {
                if (obj.validationErrors) return obj.validationErrors.split(";").join("; ")
                return ""
            },
        },


    ]



    if (details) {
        return <TableContainer component={Paper} style={{marginTop: '30px'}}>
            <Table sx={{minWidth: 650}} size='small'>
                <TableHead>
                    <TableRow>
                        {headers.map(h => <TableCell key={h.property}>{h.label}</TableCell>)}
                    </TableRow>
                </TableHead>

                <TableBody>
                    {details.map(b => {
                        return <TableRow key={b.id} id={b.id}>
                            {headers.map(h => {
                                return <TableCell key={`${b.id}${h.property}`}>{h.getValue(b)}</TableCell>
                            })}
                        </TableRow>
                    })
                    }
                </TableBody>

            </Table>
        </TableContainer>
    }
}

export default BatchDetailTable