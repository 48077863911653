import React, { Fragment } from 'react'

import AddAddress from './AddAddress'
import AddContact from './AddContactPoint'
import AddAdditionalName from './AddAdditionalName'
import EditAdditionalName from './EditAdditionalName'
import EditContactPoint from './EditContactPoint'
import EditAddress from './EditAddress'
import CommonWellLinking from './CommonWellLinking'

// components


// mui
import {
    Typography,
    Grid,
    Box,
    Tooltip
} from '@mui/material'
import {
    Fax, Phone, Email, Link, Sms, DeviceUnknown
} from '@mui/icons-material'

import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import BusinessIcon from '@mui/icons-material/Business';
import HomeIcon from '@mui/icons-material/Home';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import RoofingIcon from '@mui/icons-material/Roofing';
import MergeIcon from '@mui/icons-material/Merge';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RestoreIcon from '@mui/icons-material/Restore';

// utils
import { capitalizeFirstLetter, highlightText} from "../../utils/formatting"
import DeleteContactPoint from './DeleteContactPoint'
import DeleteAddress from './DeleteAddress'
import DeleteAdditionalName from './DeleteAdditionalName'
import AddressRequiredPopup from './AddressRequiredPopup'


const Label = ({ text }) => {
    return <Typography variant={'body1'} style={{color: "#7f8c8d" }}>
        {text}
    </Typography>
}


const Value = ({ text }) => {
    return <Typography variant={'body1'} display='inline'>
        {text}
    </Typography>
}

const GridRow = ({data}) => {
    const { label, value } = data

    return (
        <Fragment>
            <Grid item xs={12} sm={2}>
                <Label text={label} />
            </Grid>
            <Grid item xs={12} sm={10}>
                <Value text={value} />
            </Grid>
        </Fragment>
    )
}

const Address = ({address}) => {
    const { line1, line2, line3, line4, line5, city, state, postalCode, use, type } = address
    const adr = [line1, line2, line3, line4, line5]

    let str = adr.join(" ").trim()

    if (city && state) str += `, ${city}, ${state}`
    if (postalCode) str += ` ${postalCode}`

    let typeIcon
    let useIcon

    switch (use) {
        case 'home':
            useIcon = <Tooltip title='Use: Home'><HomeIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        case 'billing':
            useIcon = <Tooltip title='Use: Billing'><AttachMoneyIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        case 'work':
            useIcon = <Tooltip title='Use: Work'><BusinessIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        case 'temp':
            useIcon = <Tooltip title='Use: Temp'><QueryBuilderIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        case 'old':
            useIcon = <Tooltip title='Use: Old'><RestoreIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        default:
            useIcon = <Tooltip title='Use: Unknown'><DeviceUnknown style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
    }

    switch (type) {
        case 'physical':
            typeIcon = <Tooltip title='Type: Physical'><RoofingIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        case 'postal':
            typeIcon = <Tooltip title='Type: Postal'><MarkunreadMailboxIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        case 'both':
            typeIcon = <Tooltip title='Type: Both'><MergeIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        default:
            typeIcon = <Tooltip title='Type: Unknown'><DeviceUnknown style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
    }

    return  <div style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    }}>
        <EditAddress dataFromParent={address}/><DeleteAddress address={address} /> &nbsp; | &nbsp; &nbsp;{typeIcon} &nbsp; {useIcon} &nbsp; <Value text={str} />
    </div>
}
const SingleAddress = ({address}) => {
    const { line1, line2, line3, line4, line5, city, state, postalCode, use, type } = address
    const adr = [line1, line2, line3, line4, line5]

    let str = adr.join(" ").trim()

    if (city && state) str += `, ${city}, ${state}`
    if (postalCode) str += ` ${postalCode}`

    let typeIcon
    let useIcon

    switch (use) {
        case 'home':
            useIcon = <Tooltip title='Use: Home'><HomeIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        case 'billing':
            useIcon = <Tooltip title='Use: Billing'><AttachMoneyIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        case 'work':
            useIcon = <Tooltip title='Use: Work'><BusinessIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        case 'temp':
            useIcon = <Tooltip title='Use: Temp'><QueryBuilderIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        case 'old':
            useIcon = <Tooltip title='Use: Old'><RestoreIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        default:
            useIcon = <Tooltip title='Use: Unknown'><DeviceUnknown style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
    }

    switch (type) {
        case 'physical':
            typeIcon = <Tooltip title='Type: Physical'><RoofingIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        case 'postal':
            typeIcon = <Tooltip title='Type: Postal'><MarkunreadMailboxIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        case 'both':
            typeIcon = <Tooltip title='Type: Both'><MergeIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        default:
            typeIcon = <Tooltip title='Type: Unknown'><DeviceUnknown style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
    }

    return  <div style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    }}>
        <EditAddress dataFromParent={address}/><AddressRequiredPopup address={address} /> &nbsp; | &nbsp; &nbsp;{typeIcon} &nbsp; {useIcon} &nbsp; <Value text={str} />
    </div>
}

const ContactPoint = ({contact}) => {

    const { system, value, use } = contact

    let icon
    let useIcon

    switch (system) {
        case 'phone':
            icon = <Phone style={{color: '#a1a1a1', marginRight: '5px'}} />
            break
        case 'email':
            icon = <Email style={{color: '#a1a1a1', marginRight: '5px'}} />
            break
        case 'sms':
            icon = <Sms style={{color: '#a1a1a1', marginRight: '5px'}} />
            break
        case 'fax':
            icon = <Fax style={{color: '#a1a1a1', marginRight: '5px'}} />
            break
        case 'url':
            icon = <Link style={{color: '#a1a1a1', marginRight: '5px'}} />
            break
        default:
            icon = <DeviceUnknown style={{color: '#a1a1a1', marginRight: '5px'}} />
    }

    switch (use) {
        case 'home':
            useIcon = <Tooltip title='Home'><HomeIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        case 'mobile':
            useIcon = <Tooltip title='Mobile'><PhoneIphoneIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        case 'work':
            useIcon = <Tooltip title='Work'><BusinessIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        case 'temp':
            useIcon = <Tooltip title='Temp'><QueryBuilderIcon style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
            break
        default:
            useIcon = <Tooltip title='Unknown'><DeviceUnknown style={{color: '#a1a1a1', marginRight: '5px'}} /></Tooltip>
    }

    const text = `${value}`
    return <div style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    }}>
        <EditContactPoint dataFromParent={contact}/> <DeleteContactPoint contact={contact}/> &nbsp;|&nbsp; &nbsp;{icon} &nbsp; {useIcon} &nbsp;<Value text={text} />
    </div>
}

const AdditionalName = ({name}) => {

    const { firstName, lastName, middleName, suffix } = name

    const text = `${lastName} ${suffix}, ${firstName} ${middleName}`
    return <div style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap'
    }}>
        <EditAdditionalName dataFromParent={name}/> 
        <DeleteAdditionalName name={name}/> 
         &nbsp; <Value text={text} />
    </div>
}

const Separator = () => {
    return <Grid item xs={12} >&nbsp;</Grid>
}

const ChartDemographics = ({person}) => {

    const { firstName, lastName, birthDate, gender, id, Addresses, ContactPoints, AdditionalNames, FileImportDetails } = person 

    var date = new Date('1940-01-01').toISOString()
    var highlight = date.valueOf() >= birthDate.valueOf()

    const elements = [
        {label: 'First Name', value: firstName},
        {label: 'Last Name', value:  lastName},
        {label: 'DOB', value: highlight ? highlightText(birthDate,'#fafa02') : birthDate },
        {label: 'Gender', value: capitalizeFirstLetter(gender)},
        {label: 'ID', value: id}
    ]

    if (FileImportDetails[0]?.batchId) {
        elements.push({label: 'Batch Id', value: FileImportDetails[0].batchId})
    }

    if (FileImportDetails[0]?.clientRefNo) {
        elements.push({label: 'Client Ref No', value: FileImportDetails[0].clientRefNo})
    }

    return <Fragment>
        <Box display='flex' justifyContent='flex-mid'>
                    <CommonWellLinking person={person} />
                </Box>
    <Grid container spacing={0} style={{marginTop: 10}}>
        {elements.map(e => (
            <GridRow key={e.label} data={e} />
        ))}
 
        <Separator />

        <Grid item xs={12} sm={2}>
            <Label text={'Alias'} />
        </Grid>
        <Grid item xs={12} sm={10}>
        <Box display='flex' justifyContent='flex-mid'>
                <AddAdditionalName />
                </Box>
            <Grid container spacing={0}>
            {AdditionalNames && AdditionalNames.map(n => (
                <Grid key={n.id} item xs={12}>
                    <AdditionalName name={n} />
                </Grid>
            ))}

            </Grid>
        </Grid>

        <Separator />

        <Grid item xs={12} sm={2}>
            <Label text={'Addresses'} />
        </Grid>
        <Grid item xs={12} sm={10}>
            <Box display='flex' justifyContent='flex-mid'>
                <AddAddress/>
            </Box>
            <Grid container spacing={0}>
                {Addresses && Addresses.map(a => (
                    <Grid key={a.id} item xs={12}>
                        { Addresses.length === 1 && <SingleAddress address={a} />}
                        { Addresses.length > 1 && <Address address={a} />}
                    </Grid>
                ))}
            </Grid>
        </Grid>

        <Separator />

        <Grid item xs={12} sm={2}>
            <Label text={'Contact'} />
        </Grid>
        <Grid item xs={12} sm={10}>
        <Box display='flex' justifyContent='flex-mid'>
                <AddContact />
                </Box>
            <Grid container spacing={0}>
            {ContactPoints && ContactPoints.map(c => (
                <Grid key={c.id} item xs={12}>
                    <ContactPoint contact={c} />
                </Grid>
            ))}

            </Grid>
        </Grid>
        
    </Grid>
    </Fragment>

}

export default ChartDemographics